

















import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// Store
import MasterExercisesModule from '@/store/modules/master/exercises'
// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// Components
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import TaskCommonInformation from '@/components/views/exercise/TaskCommonInformation.vue'
import TaskPracticeInformation from '@/components/views/exercise/TaskPracticeInformation.vue'
// Types
import {
  EducationLargeExerciseResource,
  EducationMasterGroupResource, ExerciseStatus,
  TaskType,
} from '@/store/types'
import { compareMonths } from '@/utils/functions'

@Component({
  components: {
    LinkTabs,
    TaskCommonInformation,
    TaskPracticeInformation,
  },
})
export default class ExercisesItemTask extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  /*private beforeRouteLeave(to: any, from: any, next: any) {
    if (!this.confirmLeave()) {
      next(false)
    } else {
      next()
    }
  }

  private beforeDestroyed() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }

  private beforeWindowUnload(e: any) {
    if (!this.confirmLeave()) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  private confirmLeave() {
    return window.confirm('Вы действительно хотите покинуть страницу?')
  }*/

  private get taskUUID () {
    return this.$route.params.taskUUID
  }

  private get tabs () {
    if (this.exercise && this.task) {
      return [{
        exact: false,
        name: 'Домашняя работа',
        to: {
          name: 'master.exercises.item.task.answer',
          params: {
            exerciseUUID: this.exercise.uuid,
            groupID: this.masterGroup.id.toString(),
            taskUUID: this.task.uuid,
          },
        },
      }, {
        exact: false,
        name: 'Сообщения',
        to: {
          name: 'master.exercises.item.task.messages',
          params: {
            exerciseUUID: this.exercise.uuid,
            groupID: this.masterGroup.id.toString(),
            taskUUID: this.task.uuid,
          },
        },
      }]
    }
    return []
  }

  // Актуален ли месяц выдачи дз
  private get checkActualMonth() {
    return compareMonths(this.exercise.spendingAt)
  }

  private get task () {
    return MasterExercisesModule.task
  }

  private get isPractice () {
    return this.task && this.task.type.value === TaskType.PRACTICE
  }

  private get isTaskRootRoute () {
    return this.$route.name === 'master.exercises.item.task'
  }

  private get isNewEditor() {
    if (this.task) {
      if (this.task.content === '') return true
      return typeof JSON.parse(this.task.content) === 'string'
    }
    return true
  }

  private get isStatusWait () {
    const { value } = this.exercise.progress.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private created () {
    //window.addEventListener('beforeunload', this.beforeWindowUnload)
    this.syncTask()
    this.$bus.$on('updateTask', this.fetchExerciseTask)
  }

  private destroyed () {
    this.$bus.$off('updateTask', this.fetchExerciseTask as any)
    MasterExercisesModule.clearExerciseTask()
    MasterExercisesModule.clearTaskMessages()
  }

  private syncTask () {
    if (!this.task || this.taskUUID !== this.task.uuid || this.isTaskRootRoute) {
      this.fetchExerciseTask()
    }
  }

  private fetchExerciseTask () {
    MasterExercisesModule.fetchExerciseTask({
      exerciseUUID: this.exercise.uuid,
      masterGroupID: this.masterGroup.id,
      taskUUID: this.taskUUID,
    })
      .then(response => {
        // Если тест в статусе checked, то запрашиваем инфу о РНО, с целью узнать нужно ли его проходить
        if (response.status.value !== ExerciseStatus.WAIT && response.forms[0].isWorkOnMistakesRequired && response.forms.length === 1 && (response.forms[0].correct !== response.forms[0].questions || response.forms[0].rate !== response.maxPoints)) {
          MasterExercisesModule.fetchMistakes({ formID: response.forms[0].id, masterGroupID: this.masterGroup.id })
            .catch(this.notifyError)
        }
        if (this.isTaskRootRoute) {
          this.$router.replace({
            name: 'master.exercises.item.task.answer',
            params: {
              exerciseUUID: this.exercise.uuid,
              groupID: this.masterGroup.id.toString(),
              taskUUID: this.taskUUID,
            },
          })
            .catch(() => {return})
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'master.exercises' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.task ? this.task.title : 'Домашняя работа',
    }
  }

  @Watch('$route')
  private watchRoute () {
    this.syncTask()
  }
}
